import { useState } from "react";
import * as styles from "./app.module.scss";
import 'ui';
import cn from "classnames";


type TechnicalInformation = {
    plainSectionHeading: string;
    plainSectionDescription: string;
    plainSectionItems: TechnicalInformationItem[];
    accordionSectionHeading: string;
    accordionSectionDescription: string;
    accordionSectionItems: TechnicalInformationItem[];
}

type TechnicalInformationItem = {
    heading: string;
    description: string;
};

const translationState = window.app.preloadState.translation;
const state: TechnicalInformation = (window as any).app.preloadState.technicalInformation;

function App() {
    const [showAllItems, setShowAllItems] = useState(false);
    const [showAllPlainItems, setShowAllPlainItems] = useState(false);

    return (<>
        {state.plainSectionItems.length > 0 &&
            <div className="technicalInformation" id="plainTechnicalInfo">
                <h2>{state.plainSectionHeading}</h2>
                {!!state.plainSectionDescription && <div className="technicalText">{state.plainSectionDescription}</div>}
                <div className="accordion">
                    {state.plainSectionItems.slice(0, showAllPlainItems ? 9999 : 6).map((item, index) => <div key={index} className="details d-flex justify-content-space-between">
                        <div>{item.heading}</div>
                            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </div>)}
                    {state.plainSectionItems.length > 6 &&
                        <div className={cn(styles.seeMoreUnderline, "details d-flex text-center justify-content-center user-select-none cursor-pointer font-weight-bold")} onClick={() => { setShowAllPlainItems(!showAllPlainItems) }}>
                            <div>{showAllPlainItems ? translationState["common.seeLess"] : translationState["common.seeMore"]}</div>
                        </div>
                    }
                </div>
            </div>
        }
        {state.accordionSectionItems.length > 0 &&
            <div className="technicalInformation" id="technicalInfo">
                <h2>{state.accordionSectionHeading}</h2>
                {!!state.accordionSectionDescription && <div className="technicalText">{state.accordionSectionDescription}</div>}
                <div className="accordion">
                    {state.accordionSectionItems.slice(0, showAllItems ? 9999 : 6).map((item, index) => <div key={index} className="details">
                        <AccordionSectionItem heading={item.heading} description={item.description} />
                    </div>)}
                    {state.accordionSectionItems.length > 6 &&
                        <div className={cn(styles.seeMoreUnderline, "details d-flex text-center justify-content-center user-select-none cursor-pointer font-weight-bold")} onClick={() => { setShowAllItems(!showAllItems) }}>
                            <div>{showAllItems ? translationState["common.seeLess"] : translationState["common.seeMore"]}</div>
                        </div>
                    }
                </div>
            </div>
        }
    </>)
}

function AccordionSectionItem({ heading, description }: {heading: string, description: string}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <div className={cn("wholeItem", isOpen && "wholeItemOpen")}>
            <input type="checkbox" name={heading} id={heading} />
            <label htmlFor={heading} onClick={() => setIsOpen(!isOpen)}>{heading}</label>
        <div className={cn("content", styles.noTopPadding)} dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
}

export default App;
